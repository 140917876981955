<template lang="pug">
  include ../mixins
  +b.catalog-view
    ui-preloader(v-if="isLoad")
    +e.heading.--variant_1
      +e.total.--variant_1
        +b.P.ds-caption.--size_xs.--size_sm-xl
          b {{ paginationInfo.count }}
          |&nbsp;
          span {{ pluralizedProductWord }}
      +e.toggler
        +b.filter-button(@click.prevent="openFilter")
          +e.icon
            icon-filter
          +e.content
            +b.P.ds-caption.--size_sm.--color_main {{ _("Фільтри") }}
            +e.P.tags.ds-caption.--size_xxs.--color_relief-5(v-if="filterTags.length") {{ filterTags[0].title }}: {{ filterTags[0].attr.title }}
      +e.tags(v-if="filterTags.length")
        +b.g-row.--space_2sm.--appearance_spaced
          +b.g-cell(v-for="item in filterTags")
            +b.i-tag.--variant_1
              +e.SPAN.content {{ item.title }}: {{ item.attr.title }}
              +e.cross(@click.prevent="removeTag(item)")
                icon-close
      +e.sort
        multiselect.multiselect--variant_1(
          v-model="sortValue"
          track-by="value"
          label="title"
          name="sort"
          :options="sortArray"
          :allow-empty="false"
          @input="sortChangeHandler"
        )
    +e.main
      +e.filter(:class="{ 'is-active': isShowFilters }")
        +e.filter-wrapper
          +e.top
            +b.i-link.--size_sm(
              v-if="filterTags.length"
              @click.prevent="clearFilters"
            )
              +e.icon.--offset_right.--color_dark
                icon-filter-clear
              +e.SPAN.text {{ _("Скинути фільтр") }}
            +b.SPAN.ds-caption.--size_lg.--weight_bold(v-else) {{ _("Фільтр") }}
            +e.close(@click.prevent="closeFilter")
              icon-close
          +b.ds-panel.--space_md.is-hidden-xl(v-if="filterTags.length")
            +e.element.--offset_bottom
              +b.g-row.--space_2sm.--appearance_spaced
                +b.g-cell(v-for="item in filterTags")
                  +b.i-tag.--variant_1
                    +e.SPAN.content {{ item.title }}: {{ item.attr.title }}
                    +e.cross(@click.prevent="removeTag(item)")
                      icon-close
          filter-section(
            v-if="showFilters"
            ref="filter"
            :filters="filters"
            :selected-filters="selectedFilters"
            @input="filterChanged"
          )
          +e.footer
            +b.control-button(@click.prevent="closeFilter")
              +e.element
                +e.SPAN.text {{ _("Применить") }}
      +e.content(ref="content")
        renderer(
          v-if="response"
          :result="response"
          :class-render="listClass"
        )
        div(
          v-else
          :class="listClass"
        )
          slot(name="cards")
        pagination(
          v-if="paginationInfo.count > paginationInfo.per_page"
          :current="paginationInfo.page"
          :total="paginationInfo.count"
          :perPage="paginationInfo.per_page"
          :is-add-more="true"
          @changed="paginationChangeHandler"
        )
</template>

<script>
import { isEmpty } from 'lodash'
import CatalogMixin from '@mixins/catalog.mixin.js'
import { scrollToElement } from '@utils/scrollToElement'
import { simplePluralize, valueFromSelect } from '@utils/utils'
import {
  productCatalogResource,
  productCatalogWithoutFiltersResource,
  productFiltersResource,
} from '@services/catalog.service.js'
import { scrollUnlock, scrollLock } from '@utils/scroll'

const DEFAULT_SORT = 'popular'

export default {
  components: {
    'filter-section': () => import('./FilterSection'),
    pagination: () => import('@app/UiElements/Pagination'),
  },

  mixins: [
    CatalogMixin,
  ],

  props: {
    category: String,
    slug: String,
    pagination: Object,
  },

  data() {
    return {
      selectedFilters: {},
      response: '',
      sortValue: null,
      sortArray: [
        { title: this._('Найпопулярніші'), value: 'popular' },
        { title: this._('По збільшенню ціни'), value: 'price_up' },
        { title: this._('По зменшенню ціни'), value: 'price_down' },
        { title: this._('Новинки'), value: 'new' },
        { title: this._('По рейтингу'), value: 'rating' },
      ],
      filters: [],
      showFilters: false,
      paginationInfo: {
        count: this.pagination.count,
        page: this.pagination.page,
        per_page: this.pagination.perPage,
      },
      isAddPage: false,
    }
  },

  computed: {
    pluralizedProductWord() {
      const variants = [
        this._('товар'),
        this._('товари'),
        this._('товарів'),
      ]
      return simplePluralize(this.paginationInfo.count, variants)
    },

    filterTags() {
      return Object.keys(this.selectedFilters).reduce((acc, k) => {
        const item = this.filters.find(el => el.label === k)

        if (k.includes('_max')) {
          const clearKey = k.split('_')[0]
          const min = this.selectedFilters[`${clearKey}_min`]
          const max = this.selectedFilters[`${clearKey}_max`]
          const rangeItem = this.filters.find(el => el.label === clearKey)

          acc.push({
            label: clearKey,
            type: 'range',
            title: rangeItem.title,
            attr: {
              title: `${min[0]} ${this._('₴')} - ${max[0]} ${this._('₴')}`,
            },
          })
        } else if (item && Array.isArray(item.options) && Array.isArray(this.selectedFilters[k]) && item.type !== 'range') {
          const preparedValues = this.selectedFilters[k].map(el => (el).toString())
          const options = item.options.filter(o => preparedValues.includes((o.label).toString()))

          options.forEach(v => {
            acc.push({
              label: k,
              type: item.type,
              title: item.title,
              attr: {
                title: v.title,
                label: v.label,
              },
            })
          })
        }

        return acc
      }, [])
    },

    listClass() {
      const arr = ['products-list']

      if (!this.paginationInfo.count) {
        arr.push('is-spaced')
      }

      return arr.join(' ')
    },
  },

  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },

  async mounted() {
    this.preselectSort()

    await this.getFilters()

    this.prepareFilterData()

    this.checkUrl(this.category)
  },

  methods: {
    preselectSort() {
      this.sortValue = this.sortArray.find(el => el.value === DEFAULT_SORT)
    },

    filterChanged(e) {
      this.selectedFilters = e

      this.getCards()
    },

    async getFilters() {
      const params = {
        slug: this.category,
        filters: this.selectedFilters,
      }

      const { data } = await productFiltersResource.execute(params)

      this.filters = data.items
    },

    prepareParams(page) {
      this.selectedFilters = this.removeEmpty(JSON.parse(JSON.stringify(this.selectedFilters)))

      this.selectedFilters.sort = [valueFromSelect(this.sortValue, 'value')]

      if (this.selectedFilters.sort[0] === DEFAULT_SORT) {
        delete this.selectedFilters.sort
      }

      delete this.selectedFilters.price

      return {
        slug: this.category,
        filters: this.selectedFilters,
        page,
      }
    },

    async getCards(page = 1, needPushState = true) {
      this.isLoad = true

      const params = this.prepareParams(page)

      const resource = isEmpty(params.filters) ? productCatalogWithoutFiltersResource : productCatalogResource

      const res = await resource.execute(params)

      if (this.isAddPage) {
        this.response += res.data
      } else {
        this.response = res.data
      }

      this.paginationInfo = res.meta

      if (needPushState) {
        this.pushHistory()
      }

      this.isLoad = false
    },

    prepareFilterData() {
      // open filter sections
      Object.keys(this.selectedFilters).forEach(key => {
        this.filters.forEach(item => {
          if (key.split('_')[0] === item.label) {
            item.isOpen = true
          }
        })
      })
      this.showFilters = true
    },

    clearFilters() {
      Object.keys(this.selectedFilters).forEach(k => {
        if (k.includes('_min') || k.includes('_max')) {
          const clearKey = k.split('_')[0]

          this.$refs.filter.clearWidget(clearKey)
        } else {
          this.$refs.filter.clearWidget(k)
        }
      })

      this.selectedFilters = {}

      this.isAddPage = false

      this.getCards()
    },

    sortChangeHandler() {
      this.getCards()
    },

    async paginationChangeHandler(info) {
      this.isAddPage = info.isLoadMore

      this.paginationInfo.page = info.page

      scrollLock()

      if (!this.response && this.isAddPage) {
        await this.getCards(info.page - 1, false)
        await this.getCards(info.page)
      } else {
        await this.getCards(info.page)
      }

      scrollUnlock()

      if (!this.isAddPage) {
        scrollToElement(this.$refs.content)
      }

      this.isAddPage = false
    },

    removeTag(tag) {
      if (tag.type === 'range') {
        delete this.selectedFilters[`${tag.label}_min`]
        delete this.selectedFilters[`${tag.label}_max`]

        this.selectedFilters[tag.label] = []

        this.$refs.filter.clearWidget(tag.label)
      } else {
        this.selectedFilters[tag.label] = this.selectedFilters[tag.label].filter(v => (v).toString() !== (tag.attr.label).toString())
      }

      this.getCards()
    },
  },
}
</script>
