<template lang="pug">
include ../mixins
+b.card.--variant_6.relative
  ui-preloader.i-preloader--variant_1(v-if="isLoad")
  +b.ds-panel.--space_md.--space_lg-md.--space_2xl-xl
    +e.element.--offset_bottom
      +b.g-row.--appearance_spaced.--align_center
        +b.g-cell
          +b.ds-inliner.--size_lg.--size_xl-xl
            +e.ICON-LOCATION-TICK.body
        +b.g-cell
          +b.P.ds-caption.--size_md.--size_3md-xl.--weight_medium {{ _("Доставка в") }}
            |&nbsp;
            ui-city-select(
              @city:changed="getData"
              #default="{ open, city }"
            )
              +b.SPAN.ds-link.--size_md.--size_3md-xl.--color_main.--weight_medium.--hover_underline.--inline(
                @click.prevent="open"
              ) {{ city.name }}
  +b.d-table
    +e.item(v-for="item in items")
      +e.cell.--width_1
        +b.P.ds-caption.--size_xs.--size_2xs-xl.--color_dark {{ item.name }}
      +e.cell.--width_2
        +b.P.ds-caption.--size_xs.--size_2xs-xl.--color_dark.--weight_bold {{ item.date }}
      +e.cell.--width_3
        +b.P.ds-caption.--size_xs.--size_2xs-xl.--color_dark.--appearance_right(
          :class="item.freeDelivery ? 'ds-caption--color_green' : 'ds-caption--color_dark'"
        ) {{ item.cost }}
</template>

<script>
import { mapState } from 'vuex'
import { deliveryMethodsResource } from '@services/delivery.service.js'

export default {
  props: {
    productId: {
      type: [Number, String],
    },
  },

  data() {
    return {
      items: [],
      isLoad: false,
    }
  },

  computed: {
    ...mapState('user', ['city']),
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      this.isLoad = true

      const formdata = {
        city: this.city.id,
        product: this.productId,
      }

      const { data: { item } } = await deliveryMethodsResource.execute({}, formdata)

      this.isLoad = false

      this.items = item.methods
    },
  },
}
</script>
